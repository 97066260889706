<template>
    <main>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label text-black">Seleccione rango de fechas</label>
                    <date-picker class="w-100" v-model="range" range valueType="format"></date-picker>
                    <label class="form-label text-black">Seleccione el tipo de RIPS</label>
                    <select class="form-control form-control-sm" v-model="tipo">
                        <option value="RN">Recien Nacido</option>
                        <option value="N">Niños y Niñas</option>
                        <option value="A">Adultos</option>
                    </select>
                </div>
                <button class="btn btn-success shadow-sm btn-sm" @click="report()">
                    <i class="fa fa-file-excel fa-fw "></i>
                    Generar Reporte
                </button>
            </div>
        </div>
    </main>
</template>
<script>
import registroPaiService from "../../../services/regitroPaiService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from "dayjs";

export default {
    components:{
        DatePicker
    },
    data() {
        return {
            range: [
                dayjs().format('YYYY-MM-DD'),
                dayjs().format('YYYY-MM-DD')
            ],
            tipo : 'RN',
        }
    },
    methods: {
         report(){
           registroPaiService.reportGeneralVacunas(
                this.range[0],
                this.range[1],
                this.tipo
            );
        },
    },
}
</script>
<style scoped>
    .scroll {
    max-height: 400px;
    overflow-y: auto;
}
</style>
