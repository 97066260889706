<template>
    <main>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon">
                                    <i class="fa fa-file-excel fa-fw"></i>
                                </div>
                                Registro Diario de Vacunas
                            </h1>
                            <div class="page-header-subtitle">
                                RIPS DIGITAL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <br>
        <div class="container-xl px-4 mt-n15">
            <div class="row">
                <div class="col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xxl-4 offset-xxl-4">
                    <div class="card">
                        <div class="card-body">
                            <reportGeneral></reportGeneral>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import reportGeneral from "./reportGeneral";
export default {
    components: {reportGeneral},
}
</script>
